/* styles.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-height: 100vh;
    overflow-y: auto;
  }
  @media (max-width:1200px){
    .popup-content {
      min-width: 100%;
    }
  }
  @media (min-width:1200px){
    .popup-content {
      min-width: 1200px;
      max-width: 1200px;
    }
  }
