.ep_container{
 padding-bottom: 15px;
}

.ep_container .ep_wrapper > li{
    border-radius: 999px;
    color: white;
}
.ep_container .ep_wrapper > li.active{
    background-color: #ffffff;
    color: #7D0102;
}

.ep_container .ep_wrapper > li.active > a{
    color: #7D0102;
}