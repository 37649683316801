body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #f19292;
}

.desktop {
  display: none;
}

@media (min-width: 768px) {
  .desktop {
    display: block;
  }
}

.rounded-br-148 {
  border-bottom-right-radius: 148px;
}
.rounded-bl-148 {
  border-bottom-left-radius: 148px;
}
.filter-menue-wrapper {
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  position: relative;
  scrollbar-width: none;
}
li.shearbutton {
  transition: all 0.6s ease-in-out;
}
li.sh-btn-active {
  transform: translateX(50px);
  animation-name: opacityChange 1s ease-in-out;
}

li.sh-btn-inactive {
  transform: translateX(1000px);
  animation-name: opacityChange 1s ease-in-out;
}

div.filter-icon-wrapper {
  z-index: 110;
}
.filter-menue-wrapper > li {
  scroll-snap-align: start;
}

.animation {
  animation-name: fedIn 1s ease-in-out;
}
@keyframes fadeIn {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #ff0000;
  }
}
@keyframes opacityChange {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
ul.menue_wrapper {
  transition: all 2s ease-in-out;
  z-index: 10;
}
ul.filterOut {
  transform: translateX(0px);
}

.fileterActive {
  transform: translateX(0);
}

li.activeFilter {
  position: relative; /* Ensure positioning context for pseudo-elements */
  transition: all 0.5s ease-in-out;
}

li.activeFilter::before,
li.activeFilter::after {
  content: ""; /* Necessary for pseudo-elements */
  position: absolute;
  width: 72px;
  height: 22px;
  background-color: transparent; /* Set background color as needed */
  transition: all 1s ease-in-out;
  border-radius: 100%;
}

li.activeFilter::before {
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgba(255, 255, 255, 0.938);
}

li.activeFilter::after {
  top: -20px; /* Position above the element */
  left: 50%; /* Position horizontally in the center */
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.938);
}

.comic_item_card {
  transition: transform 0.3s ease;
}

.comic_items_wrapper > .comic_item_card:hover {
  transform: scale(1.02);
}

.error-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #ff3d00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* medea screen config style */
@media (max-width: 400px) {
  .md-screen-350 {
    flex-direction: column;
  }
}
